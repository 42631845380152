import { createJSONStorage, persist } from 'zustand/middleware';
import { createStore } from 'zustand/vanilla';

export type SessionState = {
    code: string;
};

export type SessionActions = {
    setCode: (code: string) => void;
};

export type SessionStore = SessionState & SessionActions;

export const initSessionStore = (): SessionState => {
    return { code: '' };
};

export const defaultInitState: SessionState = {
    code: '',
};

export const createSessionStore = (initState: SessionState = defaultInitState) => {
    return createStore<SessionStore>()(
        persist(
            (set, get) => ({
                ...initState,
                setCode: (code: string) =>
                    set((state) => ({
                        code: code,
                    })),
            }),
            {
                name: '_session',
                storage: createJSONStorage(() => sessionStorage),
            },
        ),
    );
};
