import { createContext, type ReactNode, useContext, useRef } from 'react';
import { type StoreApi, useStore } from 'zustand';
import { createSessionStore, initSessionStore, type SessionStore } from '@store/session.store';

export const SessionStoreContext = createContext<StoreApi<SessionStore> | null>(null);

export interface SessionProviderProps {
    children: ReactNode;
}

export const SessionStoreProvider = ({ children }: SessionProviderProps) => {
    const storeRef = useRef<StoreApi<SessionStore>>();
    if (!storeRef.current) {
        storeRef.current = createSessionStore(initSessionStore());
    }

    return <SessionStoreContext.Provider value={storeRef.current}>{children}</SessionStoreContext.Provider>;
};

export const useSessionStore = <T,>(selector: (store: SessionStore) => T): T => {
    const counterStoreContext = useContext(SessionStoreContext);

    if (!counterStoreContext) {
        throw new Error(`useSessionStore must be use within useSessionStore`);
    }

    return useStore(counterStoreContext, selector);
};
